import { useMutation, useQuery } from "@apollo/client";
import { ArrowUpIcon } from "@heroicons/react/outline";
import { compact, debounce, last, uniqBy } from "lodash";
import { FormEvent, useEffect, useRef, useState } from "react";
import {
  AskAiSessionChatMessageFragment,
  ChatbotRoleEnum,
  GetAskAiChatSessionMessagesQueryQuery,
  GetAskAiChatSessionMessagesQueryQueryVariables,
} from "types/graphql-schema";

import Artifact from "@apps/artifact/artifact";
import Loading from "@components/loading/loading";
import { onNotificationErrorHandler } from "@components/use-error/use-error";
import { classNames } from "@helpers/css";
import {
  assertEdgesNonNull,
  assertEdgesNonNullWithStringId,
} from "@helpers/helpers";

import getAskAIChatSessionMessagesQuery from "./graphql/get-ask-ai-chat-session-messages-query";
import sendMessageToAskAIMutation from "./graphql/send-message-to-chatbot-mutation";

const AskAIMessage = ({
  message,
}: {
  message: AskAiSessionChatMessageFragment;
}) => {
  const actionItems = message.actionItems
    ? assertEdgesNonNull(message.actionItems)
    : [];
  const goals = message.goals ? assertEdgesNonNull(message.goals) : [];
  return (
    <div
      className={classNames(
        "flex w-full",
        message.role === ChatbotRoleEnum.User
          ? "justify-end pl-12"
          : "justify-start pr-12"
      )}
      key={message.id}
    >
      <div
        className={classNames(
          "border rounded-lg p-1 px-2 whitespace-pre-line text-gray-600 bg-white",
          message.role === ChatbotRoleEnum.User &&
            "bg-blue-50 border-blue-200 text-blue-700"
        )}
      >
        {message.content}
        <div className="mt-2 empty:hidden flex flex-col divide divide-y rounded-lg border mb-1">
          {actionItems.map((artifact) => (
            <div className="px-2 py-1" key={artifact.id}>
              <Artifact artifact={artifact} />
            </div>
          ))}
          {goals.map((artifact) => (
            <div className="px-2 py-1" key={artifact.id}>
              <Artifact artifact={artifact} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const AskAI = ({
  meetingId,
  chatSessionId,
  onChangeChatSessionId,
}: {
  chatSessionId: number | null;
  onChangeChatSessionId: (chatSessionId: number) => void;
  meetingId?: number;
}) => {
  const scrollRef = useRef<null | HTMLDivElement>(null);
  const [showAssistantLoading, setShowAssistantLoading] = useState(false);
  const [messages, setMessages] = useState<AskAiSessionChatMessageFragment[]>(
    []
  );
  const [inputMessage, setInputMessage] = useState("");

  const [sendMessageToAskAI, { loading: loadingSubmit }] = useMutation(
    sendMessageToAskAIMutation
  );
  const { data, loading } = useQuery<
    GetAskAiChatSessionMessagesQueryQuery,
    GetAskAiChatSessionMessagesQueryQueryVariables
  >(getAskAIChatSessionMessagesQuery, {
    variables: { chatSessionId: chatSessionId || -1 },
    skip: !chatSessionId,
    onError: onNotificationErrorHandler(),
  });

  const setUniqueMessages = (messages: AskAiSessionChatMessageFragment[]) => {
    setMessages(uniqBy(messages, "id"));
  };

  const sendMessage = (message: string) => {
    const newMessage: AskAiSessionChatMessageFragment = {
      __typename: "ChatMessageNode",
      id: `${chatSessionId}-${messages.length + 1}`,
      role: ChatbotRoleEnum.User,
      content: message,
      autogenerated: false,
      actionItems: {
        edges: [],
        __typename: "ActionItemArtifactConnection",
      },
      goals: {
        edges: [],
        __typename: "GoalArtifactConnection",
      },
    };
    setUniqueMessages([...messages, newMessage]);
    setShowAssistantLoading(true);
    sendMessageToAskAI({
      variables: {
        chatSessionId,
        meetingId: meetingId,
        message,
      },
      onCompleted: (response) => {
        if (!chatSessionId) {
          onChangeChatSessionId(response.sendMessageToChatbot.chatSession.id);
        }
      },
      onError: onNotificationErrorHandler(),
    });
  };
  const debouncedSendMessage = debounce(sendMessage, 10);

  const handleSubmitMessage = (e: FormEvent) => {
    e.preventDefault();
    if (inputMessage.trim().length === 0) {
      return;
    }
    debouncedSendMessage(inputMessage);
    setInputMessage("");
  };

  const isLastMessageFromAssistant =
    last(messages)?.role === ChatbotRoleEnum.Assistant;

  useEffect(() => {
    const newMessages = data?.chatSession?.messages
      ? assertEdgesNonNullWithStringId(data.chatSession.messages)
      : [];
    setUniqueMessages(compact(newMessages));
  }, [data]);

  useEffect(() => {
    const scrollToBottomOfMessages = () => {
      if (messages.length > 0 && scrollRef.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    };
    scrollToBottomOfMessages();
  }, [loading, messages.length, showAssistantLoading]);

  useEffect(() => {
    let st = null;
    if (isLastMessageFromAssistant) {
      setShowAssistantLoading(false);
    } else if (showAssistantLoading) {
      st = setTimeout(() => {
        setShowAssistantLoading(false);
      }, 30 * 1000); // 20s seems long enough?
    }
    return function cleanup() {
      if (st) {
        clearTimeout(st);
      }
    };
  }, [showAssistantLoading, isLastMessageFromAssistant]);

  const filteredMessages =
    messages.filter(
      ({ autogenerated, role }) =>
        !autogenerated && role !== ChatbotRoleEnum.System
    ) || [];

  return (
    <div className="rounded-lg bg-white border">
      <div className="text-sm flex flex-col">
        {filteredMessages.length === 0 && loading ? (
          <Loading size="5" className="px-3 py-2 border-b" />
        ) : filteredMessages.length === 0 ? null : (
          <div
            className="p-3 flex flex-col gap-3 max-h-120 overflow-y-scroll border-b"
            ref={scrollRef}
          >
            {filteredMessages.map((message) => (
              <AskAIMessage message={message} key={message.id} />
            ))}
            {showAssistantLoading && (
              <div>
                <Loading mini size="5" />
              </div>
            )}
          </div>
        )}
        <form className="relative" onSubmit={handleSubmitMessage}>
          <input
            autoFocus
            type="text"
            className={classNames(
              "w-full px-3 pr-12 py-3 focus:ring-0 focus:outline-0",
              filteredMessages.length === 0 ? "rounded-lg" : "rounded-b-lg"
            )}
            placeholder="Ask anything about this meeting..."
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
          />
          <button
            type="submit"
            disabled={inputMessage.trim().length === 0 || loadingSubmit}
            className={classNames(
              "absolute right-2 top-2 z-1",
              "rounded-full p-1.5 bg-gray-100 text-gray-500",
              inputMessage.trim().length > 0 &&
                "bg-blue-link text-white hover:bg-blue-800"
            )}
          >
            <ArrowUpIcon className="h-4 w-4" />
          </button>
        </form>
      </div>
    </div>
  );
};

export default AskAI;
